.facilityServiceFormWrapper {
  margin-top: 2rem;
}

.facilityDeviceTableWrapper {
  margin-top: 2rem;
}

.historyButtonsWrapper {
  margin-top: 3.5rem;
  display: flex;
  flex-direction: column;
  font-size: 1.25rem;

  :global {
    .title {
      margin-bottom: 1rem;
    }

    .buttonGroup {
      display: flex;
      justify-content: space-around;
    }
  }
}

.facilityStatsWrapper {
  margin-bottom: 3.5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  font-size: 1.25rem;

  :global {
    .title {
      margin-bottom: 1rem;
    }
  }
}
