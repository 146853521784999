.filterLabel {
  width: 20rem;

  :global {
    .ant-form-item-label {
      text-align: right;
    }
  }
}

.dateFilter {
  :global {
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      text-align: left;
    }
  }
}
