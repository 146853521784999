@import "styles/variables";

.ManagerRechargeHistoryTableTitle {
  display: flex;
  justify-content: space-between;

  :global {
    .ant-form {
      width: 100%;
    }
  }
}

.rechargeCount {
  margin-left: 0.5rem;
}

.exportButton {
  margin-left: 1rem;
}

.tableFooterWrapper {
  :global {
    .ant-table-footer {
      text-align: right;
      font-weight: $font-weight-600;
    }
  }
}
