@import "styles/colors";
@import "styles/variables";

.container {
  height: 100%;
}

.content {
  background: $bg-white;
  padding: $spacing-6;
  margin: 0 $spacing-6 $spacing-6;
}

.pageHeader {
  :global {
    .ant-page-header-footer .ant-tabs .ant-tabs-tab {
      font-size: $font-size-14;
    }

    .ant-page-header-heading {
      flex-wrap: wrap;
      order: 2;
      flex-grow: 1;
    }
    .ant-page-header-heading-title {
      white-space: normal;
      margin-left: 0.75rem;
    }
    .ant-page-header-heading-extra {
      margin-left: auto;
    }

    display: flex;
    position: sticky;
    top: 0;
    z-index: map-get($zLayers, navigationHeader);
  }
}

.pageHeader,
.filters {
  margin-bottom: $spacing-6;
}

.trigger {
  font-size: 18px;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: #1890ff;
  }
}

.logoutMenu {
  display: flex;
  align-items: center;

  :global {
    .anticon-logout {
      margin-right: 0.5rem;
    }
  }
}

.avatarDropdown {
  cursor: pointer;

  :global {
    .anticon-down {
      margin-left: 0.5rem;
    }
  }
}

.menuOverlayWrapper {
  position: fixed;
  top: 44px;
}
