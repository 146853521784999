.title {
  margin-bottom: 1rem;
}

.businessOverviewWrapper {
  margin-bottom: 0.5rem;

  :global {
    .ant-card {
      height: 100%;
    }

    .ant-statistic-content-value {
      max-width: 100%;
      overflow-wrap: break-word;
      display: inline;
    }

    .ant-statistic-content-suffix {
      display: inline;
    }
  }
}
