@import "./variables";
@import "./colors";

.font-weight-bold {
  font-weight: $font-weight-700;
}

.text-red {
  color: $text-red;
}

.text-yellow {
  color: $text-yellow;
}

.text-green {
  color: $text-green;
}
