.filterLabel {
  width: 17.5rem;

  :global {
    .ant-form-item-label {
      text-align: right;
    }
  }
}

.genderFilter {
  :global {
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      text-align: left;
    }
  }
}
