.filterLabel {
  width: 20rem;

  :global {
    .ant-form-item-label {
      text-align: right;
    }
  }
}

.dateFilter {
  :global {
    .ant-select-selection-placeholder,
    .ant-select-selection-item {
      text-align: left;
    }
  }
}

.statsWrapper {
  :global {
    .ant-card {
      height: 100%;
    }

    .ant-statistic-content-value {
      max-width: 100%;
      overflow-wrap: break-word;
      display: inline;
    }

    .ant-statistic-content-suffix {
      display: inline;
    }
  }
}
