.titleWrapper {
  display: flex;
  place-content: space-between;
}

.usageCountWrapper {
  align-self: center;
}

.usageCount {
  margin-left: 0.5rem;
}
