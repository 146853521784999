.usageHistoryTableTitle {
  display: flex;
  justify-content: space-between;

  :global {
    .ant-form {
      width: 100%;
    }
  }
}

.usageCount {
  margin-left: 0.5rem;
}

.exportButton {
  margin-left: 1rem;
}
