.facilityTableTitle {
  display: flex;
  justify-content: space-between;

  :global {
    .clearEnvironmentHistory {
      margin-right: 1.5rem;
    }
  }
}
