// Spacing
$spacing-0: 0;
$spacing-1: 4px;
$spacing-2: 8px;
$spacing-3: 12px;
$spacing-4: 16px;
$spacing-5: 20px;
$spacing-6: 24px;
$spacing-7: 32px;
$spacing-8: 48px;
$spacing-9: 64px;

// Font sizes
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-32: 32px;
$font-size-40: 40px;

// Font weight
$font-weight-100: 100;
$font-weight-200: 200;
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;

// Navigation
$navbar-height: 64px;
$sidebar-width: 256px;

// Basic setup for handling of z-layers,
// Layers can be extended if needed
// use like:
// z-index: map-get($zLayers, navigationHeader);
$zLayers: (
  navigationHeader: 500,
);
