.title {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.mapButton {
  margin-top: 0.375rem;
}

.statusRatingWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  :global {
    .ant-rate {
      margin-top: 1rem;
      font-size: 0.875rem;
    }
  }
}

.feedbackTooltip {
  margin: 0 0.5rem;
}
