@import "styles/colors";
@import "styles/helpers";

body {
  background-color: $bg-gray;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Force ant input number to take up all width when used inside col */
.ant-col {
  .ant-picker,
  .ant-input-number {
    width: 100%;
  }
}

// Fix for blurry images on retina https://stackoverflow.com/questions/13347947/css-filter-on-retina-display-fuzzy-images
img {
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

.link-color {
  color: $text-link;
}

/** Common style for wrapping long table cell texts
  - works with `ellipsis: true` prop of the column
*/

.tableCellWrap {
  white-space: pre-wrap;
}

.tableHasTitle {
  .ant-table-title {
    text-align: left;
  }
}
